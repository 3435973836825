import { createAction } from 'redux-actions';

export const OPT_IN_REQUESTED = '@@privacy-manager/OPT_IN_REQUESTED';
export const OPT_IN_FAILED = '@@privacy-manager/OPT_IN_FAILED';
export const OPTED_IN = '@@privacy-manager/OPTED_IN';
export const OPT_OUT_REQUESTED = '@@privacy-manager/OPT_OUT_REQUESTED';
export const OPT_OUT_FAILED = '@@privacy-manager/OPT_OUT_FAILED';
export const OPTED_OUT = '@@privacy-manager/OPTED_OUT';
export const RIBBON_DISMISSED = '@@privacy-manager/RIBBON_DISMISSED';

export const optInRequested = createAction(OPT_IN_REQUESTED);
export const optInFailed = createAction(OPT_IN_FAILED);
export const optedIn = createAction(
  OPTED_IN,
  () => ({}),
  (form) => ({ form }),
);
export const optOutRequested = createAction(OPT_OUT_REQUESTED);
export const optOutFailed = createAction(OPT_OUT_FAILED);
export const optedOut = createAction(
  OPTED_OUT,
  () => ({}),
  (form) => ({ form }),
);
export const ribbonDismissed = createAction(RIBBON_DISMISSED);
