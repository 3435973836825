import { select } from 'redux-most';
import { ignoreElements } from 'most-adjunct';
import * as r from 'ramda';
import * as signals from '../../signals';

export default function createBootstrapEpic() {
  return (actions, store) => r.pipe(
    select(signals.BOOTSTRAP),
    ignoreElements,
  )(actions);
}
