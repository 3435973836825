import cookies from 'browser-cookies';
import { OPTOUT_COOKIE } from '../../../domain/constants';
import { getDomain } from '../../../domain/selectors';

export const setDefaultCookieConsent = () => {
  cookies.set(OPTOUT_COOKIE, '0:0|c1:1|c2:1|c3:1|c4:1', {
    domain: getDomain(),
    path: '/',
    expires: 90, // after 90 days, just like tealium cookie
  });
}