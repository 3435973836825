// @flow
export const createEmitEvent = (
  document,
) => (evt, payload) => {
  const event = document.createEvent('CustomEvent');
  event.initCustomEvent(evt, false, false, payload);

  document.dispatchEvent(event);
};

export default null;
