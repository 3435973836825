// @flow
import { consent as makeConsent } from '@team-griffin/uteals';
import cookies, { CookieOptions } from 'browser-cookies';
import { OPTOUT_COOKIE } from '../domain/constants';
import { getDomain } from '../domain/selectors';

type Categories = {
  [category: string]: boolean,
}
type Mappings = {
  [id: string]: string, 
}

export const setCookieConsent = ({
  categories,
  mappings = {},
  cookieOptions = {}
}: {
  categories: Categories,
  mappings?: Mappings,
  cookieOptions: CookieOptions,
}) => {
  const consent = makeConsent(mappings);
  const optoutCookie = cookies.get(OPTOUT_COOKIE);
  const cookieConsent = consent.set(categories, optoutCookie);

  cookies.set(OPTOUT_COOKIE, cookieConsent, {
    domain: getDomain(),
    ...cookieOptions,
  });
}