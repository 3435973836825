import * as r from 'ramda';

export default function createFetchOpts(cookieConsent) {
  return function fetchOpts(categories) {
    if (categories[0] !== 'consent') {
      categories = r.prepend('consent', categories);
    }
    const defaults = r.pipe(
      r.map(r.objOf(r.__, {})),
      r.mergeAll,
    )(categories);
    const opts = cookieConsent().getByOrder(categories) || {};
    return r.merge(defaults, opts);
  }
}
