import { createReducer } from 'redux-create-reducer';
import {
  RIBBON_DISMISSED,
} from '../messages';
import * as r from 'ramda';

export const initialState = {
  dismissed: false,
};

export default createReducer(initialState, {
  [RIBBON_DISMISSED]: (state) => r.pipe(
    r.assoc('dismissed', true),
  )(state),
});