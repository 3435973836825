import * as r from 'ramda';
import { setCookieConsent } from './setCookieConsent';

export default function createOptIn() {
  return function OptIn(
    settings,
    expires,
  ) {
    const map = r.pipe(
      r.map(r.prop('id')),
      r.invertObj,
    )(settings);
    const values = r.map(r.prop('value'), settings);

    setCookieConsent({
      categories: values,
      mappings: map,
      cookieOptions: expires,
    });

    return Promise.resolve(true);
  }
}