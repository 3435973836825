import { select } from 'redux-most';
import { switchMap, fromEagerPromise } from 'most-adjunct';
import * as most from 'most';
import * as r from 'ramda';
import * as signals from '../../signals';
import * as messages from '../../../domain/messages';
import {
  getCategories,
  getCookiesToPreserve,
} from '../../../domain/selectors';

export default function createOptOutEpic(
  optOutRepo,
  emitEvent,
  cleanseRepo,
  fetchOptsRepo,
) {
  return (actions, store) => r.pipe(
    select(signals.OPT_OUT),
    switchMap((action) => {
      const form = action.meta.form;
      const state = store.getState();
      const categoryKeyIdMap = getCategories(state);
      const preserve = getCookiesToPreserve(state);

      const optingOut$ = fromEagerPromise(() => {
        return cleanseRepo(preserve)
          .then(() => optOutRepo(categoryKeyIdMap, 90))
          .then(r.tap(() => {
            emitEvent('@upm/opted-out', {});
          }))
          .then(() => messages.optedOut(form));
      });

      return most.merge(
        most.of(messages.optOutRequested()),
        optingOut$,
      );
    }),
  )(actions);
}
