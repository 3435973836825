import { select, selectArray, combineEpics } from 'redux-most';
import * as r from 'ramda';
import * as mostc from 'mostc';
import cookies from 'browser-cookies';
import { ignoreElements, switchMap, fromEagerPromise } from 'most-adjunct';
import * as signals from '../../signals';
import * as messages from '../../../domain/messages';
import { getTealiumConfig, hasOptoutCookie, hasPwInteractionCookie } from '../../../domain/selectors';
import { OPTOUT_COOKIE } from '../../../domain/constants';

const loadTealiumOnSubmit = (loadTealium) => {
  return (actions, store) => r.pipe(
    selectArray([
      messages.OPTED_IN,
      messages.OPTED_OUT,
    ]),
    switchMap(() => {
      const state = store.getState();
      const tealiumConfig = getTealiumConfig(state);
      const { profile, env } = tealiumConfig;

      return fromEagerPromise(() => loadTealium(profile, env).then(() => {
        window.utag.data[`cp.${OPTOUT_COOKIE}`] = cookies.get(OPTOUT_COOKIE);
        setTimeout(() => {
          window.utag.data['cp.pwinteraction'] = cookies.get('pwinteraction');
        }, 50);
        window.utag.view(window.utag.data);
      }));
    }),
    ignoreElements
  )(actions);
}

const loadTealiumOnInit = (loadTealium) => {
  return (actions, store) => r.pipe(
    select(signals.BOOTSTRAP),
    mostc.filter(() => hasOptoutCookie && hasPwInteractionCookie),
    switchMap(() => {
      const state = store.getState();
      const tealiumConfig = getTealiumConfig(state);
      const { profile, env } = tealiumConfig;

      return fromEagerPromise(() => loadTealium(profile, env));
    }),
    ignoreElements
  )(actions);
}

export default (loadTealium) => combineEpics([
  loadTealiumOnSubmit(loadTealium),
  loadTealiumOnInit(loadTealium),
]);