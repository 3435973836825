import * as r from 'ramda';
import { getDomain } from '../domain/selectors';

export default function createCleanse(
  cookies,
) {
  return function cleanse(
    preserve,
  ) {
    const items = cookies.all();

    const toCleanse = r.pipe(
      r.keys,
      r.reject(r.contains(r.__, r.append('OPTOUTMULTI', preserve))),
    )(items);

    r.forEach((key) => {
      cookies.erase(key, {
        domain: getDomain(),
      });
    })(toCleanse);

    return Promise.resolve(true);
  }
}
