import createOptIn from './optIn';
import createOptOut from './optOut';
import createSaveInteraction from './saveInteraction';
import createFetchOpts from './fetchOpts';
import {
  createEmitEvent,
} from './events';
import createCleanse from './cleanse';
import createLoadTealium from './loadTealium';

export default function createInfrastructure({
  document,
  cookies,
  navigator,
  cookieConsent,
}) {
  const fetchOptsRepo = createFetchOpts(cookieConsent);
  const optInRepo = createOptIn();
  const optOutRepo = createOptOut(fetchOptsRepo);
  const saveInteraction = createSaveInteraction(cookies);
  const emitEvent = createEmitEvent(document);
  const cleanseRepo = createCleanse(cookies);
  const loadTealium = createLoadTealium();

  return {
    optInRepo,
    optOutRepo,
    saveInteraction,
    fetchOptsRepo,
    emitEvent,
    cleanseRepo,
    loadTealium,
  };
}
