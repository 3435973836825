import * as r from 'ramda';
import { setCookieConsent } from './setCookieConsent';

export default function createOptOut(
  fetchOpts,
) {
  return function OptOut(
    categories,
    expires,
  ) {
    const settings = fetchOpts(categories);
    const map = r.pipe(
      r.map(r.prop('id')),
      r.invertObj,
    )(settings);
    const values = r.map(r.F, settings);

    setCookieConsent({
      categories: values,
      mappings: map,
      cookieOptions: expires,
    });

    return Promise.resolve(true);
  }
}
