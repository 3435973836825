import { createAction } from 'redux-actions';

export const SUBMIT_FORM = '@@privacy-manager/SUBMIT_FORM';
export const OPT_IN = '@@privacy-manager/OPT_IN';
export const OPT_OUT = '@@privacy-manager/OPT_OUT';
export const BOOTSTRAP = '@@privacy-manager/BOOTSTRAP';

export const submitForm = createAction(
  SUBMIT_FORM,
  (values, source) => ({
    values,
    source,
  }),
  (values, source, form) => ({
    form,
  }),
);
export const optIn = createAction(
  OPT_IN,
  (categories) => ({
    categories,
  }),
  (categories, form) => ({
    form,
  })
);
export const optOut = createAction(
  OPT_OUT,
  () => ({}),
  (form) => ({ form }),
);
export const bootstrap = createAction(BOOTSTRAP);
