import { createSelector } from 'reselect';
import { getFormValues, formValueSelector } from 'redux-form';
import { selectors as wlSelectors } from '@ux/whitelabel';
import * as r from 'ramda';
import cookies from 'browser-cookies';
import { OPTOUT_COOKIE } from '../constants';

export const getConfig = r.path([ 'upm', 'config' ]);
export const getRibbon = r.path([ 'upm', 'ribbon' ]);

export const getCategories = createSelector(
  getConfig,
  r.prop('categories'),
);

export const getCookiesToPreserve = createSelector(
  getConfig,
  r.prop('cookiesToPreserve'),
);

export const isRibbonDismissed = createSelector(
  getRibbon,
  r.prop('dismissed'),
);

const getInvalidateOptPre = createSelector(
  getConfig,
  r.prop('invalidateOptPre'),
);

export const getPwInteraction = () => {
  const tealiumPwInteraction = r.path([ 'utag', 'data', 'cp.pwinteraction' ], window);

  if (tealiumPwInteraction != null) {
    return tealiumPwInteraction;
  }

  return cookies.get('pwinteraction');
};

export const getMergedFormValues = createSelector(
  getFormValues('inlinePrivacy'),
  getFormValues('privacyRibbon'),
  r.merge,
);

export const shouldShowConsent = r.converge(
  (pwinteraction, invalidBefore) => {
    if (pwinteraction == null) {
      return true;
    }

    if (pwinteraction === 'yes') {
      if (invalidBefore == null) {
        return false;
      }

      return true;
    }

    let parsedPw;
    if (r.is(String, pwinteraction)) {
      parsedPw = JSON.parse(pwinteraction);
    } else {
      parsedPw = pwinteraction;
    }

    const invalidBeforeDateTime = new Date(invalidBefore)
    const timestamp = new Date(parsedPw.timestamp);

    return timestamp.getTime() < invalidBeforeDateTime.getTime();
  },
  [ getPwInteraction, getInvalidateOptPre ],
);

export const shouldSendEvent = r.T;

export const getCategoryOrder = createSelector(
  wlSelectors.getBrandConfig,
  r.pathOr([], [ 'analytics', 'tealium', 'privacy', 'categories' ]),
);

const valueSelector = formValueSelector('privacyRibbon');
export const isExpanded = (state) => valueSelector(state, 'expanded');

export const getDomain = () => {
  const tealiumDomain = r.path([ 'utag', 'cfg', 'domain' ], window);

  if (tealiumDomain != null) {
    return tealiumDomain;
  }
  //copied from utag.js to keep the same solution to setting the cookie, if tealium is not loaded
  //this will take the domain + tld even if we have .co.uk
  //account.123-reg.co.uk -> .123-reg.co.uk
  //ote.123-reg.co.uk -> .123-reg.co.uk
  try {
    const hostname = `${window.location.hostname}`;
    const hostnameParts = hostname.split('.');
    const pathSplit = /\.co\.|\.com\.|\.org\.|\.edu\.|\.net\.|\.asn\./.test(hostname) ? 3 : 2;
    const domain = hostnameParts.splice(hostnameParts.length - pathSplit, pathSplit).join('.');
    return domain;
  } catch (e) {
    return window.location.hostname;
  }
};

export const getTealiumConfig = createSelector(
  getConfig,
  r.prop('tealium'),
);

export const hasOptoutCookie = cookies.get(OPTOUT_COOKIE) != null;
export const hasPwInteractionCookie = getPwInteraction() != null;
