import { CDN, ACCOUNT } from '../domain/constants';

export default function loadTealium() {
  let mounted = false;
  
  return function(profile, env) {
    const url = `${CDN}/${ACCOUNT}/${profile}/${env}/utag.js`;

    if (mounted) {
      return Promise.resolve(void 0);
    }
  
    return new Promise((resolve, reject) => {
      const body = document.querySelector('body');
      const script = document.createElement('script');
      script.src = url;
      script.onload = () => {
        mounted = true;
        resolve();
      };
      script.onerror = reject;
      script.type = 'text/javascript';
      script.async = true;
  
      if (body != null) {
        body.appendChild(script);
      }
    });
  }
}