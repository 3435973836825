import { selectArray } from 'redux-most';
import * as mostc from 'mostc';
import * as r from 'ramda';
import * as messages from '../../../domain/messages';

export const dismissRibbonEpic = (actions) => r.pipe(
  selectArray([
    messages.OPTED_IN,
    messages.OPTED_OUT,
  ]),
  mostc.map(() => {
    return messages.ribbonDismissed();
  }),
)(actions);
