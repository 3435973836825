import cookies from 'browser-cookies';
import { cookieConsent } from '@team-griffin/uteals';
import { createStore, applyMiddleware } from 'redux';
import createInfrastructure from './infrastructure/composition';
import createApplication from './application/composition';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './domain/reducer';
import * as signals from './application/signals';

export const setupStore = (epicMiddleware) => {
  return createStore(
    reducer,
    composeWithDevTools(
      applyMiddleware(
        epicMiddleware,
      ),
    ),
  );
};

export const setupMiddleware = () => {
  const infrastructure = createInfrastructure({
    document,
    cookies,
    navigator,
    cookieConsent,
  });
  const application = createApplication({
    optInRepo: infrastructure.optInRepo,
    optOutRepo: infrastructure.optOutRepo,
    saveInteraction: infrastructure.saveInteraction,
    fetchOptsRepo: infrastructure.fetchOptsRepo,
    emitEvent: infrastructure.emitEvent,
    cleanseRepo: infrastructure.cleanseRepo,
    window,
    loadTealium: infrastructure.loadTealium,
  });

  return application.epicMiddleware;
};

export default function composition({
  config,
}) {
  const middleware = setupMiddleware();
  const store = setupStore(middleware);

  return {
    store,
    init: () => {
      store.dispatch(signals.bootstrap(config));
    },
  };
}
