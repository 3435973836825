import { getDomain } from '../domain/selectors';

export default function createSaveInteraction(cookies) {
  return function saveInteraction(expires) {
    const now = new Date();

    cookies.set(
      'pwinteraction',
      JSON.stringify({
        value: 'yes',
        timestamp: now.toISOString(),
      }),
      {
        expires,
        domain: getDomain(),
      }
    );

    return Promise.resolve(true);
  }
}
