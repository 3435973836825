import { createReducer } from 'redux-create-reducer';
import {
  BOOTSTRAP,
} from '../../application/signals';
import * as r from 'ramda';

export const initialState = {};

export default createReducer(initialState, {
  [BOOTSTRAP]: r.pipe(
    r.nthArg(1),
    r.prop('payload'),
    r.mergeDeepRight({
      domain: {
        domain: r.path([ 'utag', 'cfg', 'domain' ], window),
      },
    }),
  ),
});
