import { createEpicMiddleware, combineEpics } from 'redux-most';
import createPrivacyOptIn from './epics/privacy/optIn';
import createPrivacyOptOut from './epics/privacy/optOut';
import createPrivacySubmitForm from './epics/privacy/form';
import { dismissRibbonEpic } from './epics/privacy/ribbon';
import createBootstrapEpic from './epics/privacy/bootstrap';
import createRefreshEpic from './epics/privacy/refresh';
import createLoadTealiumEpic from './epics/privacy/loadTealium';

export default function composition({
  optInRepo,
  optOutRepo,
  saveInteraction,
  fetchOptsRepo,
  emitEvent,
  cleanseRepo,
  loadTealium,
}) {
  const submitForm = createPrivacySubmitForm(saveInteraction);
  const privacyOptIn = createPrivacyOptIn(
    optInRepo,
    cleanseRepo,
    emitEvent,
    fetchOptsRepo,
  );
  const privacyOptOut = createPrivacyOptOut(
    optOutRepo,
    emitEvent,
    cleanseRepo,
    fetchOptsRepo,
  );
  const bootstrap = createBootstrapEpic();
  const refresh = createRefreshEpic(fetchOptsRepo);
  const loadTealiumEpic = createLoadTealiumEpic(loadTealium);

  const epics = combineEpics([
    submitForm,
    privacyOptIn,
    privacyOptOut,
    dismissRibbonEpic,
    bootstrap,
    refresh,
    loadTealiumEpic,
  ]);

  const epicMiddleware = createEpicMiddleware(epics);

  return {
    epicMiddleware,
  };
}
