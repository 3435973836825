import { selectArray } from 'redux-most';
import { switchMap } from 'most-adjunct';
import * as most from 'most';
import * as mostc from 'mostc';
import * as r from 'ramda';
import * as signals from '../../signals';
import { initialize } from 'redux-form';
import {
  getCategories,
} from '../../../domain/selectors';
import * as messages from '../../../domain/messages';

export default function refreshEpic(
  fetchOptsRep,
) {
  return (actions, store) => r.pipe(
    selectArray([
      signals.BOOTSTRAP,
      messages.OPTED_IN,
      messages.OPTED_OUT,
    ]),
    mostc.filter((action) => {
      return !r.pathEq([ 'meta', 'form' ], 'inlinePrivacy', action);
    }),
    switchMap(() => {
      const state = store.getState();
      const categoryKeyIdMap = getCategories(state);
      const options = fetchOptsRep(categoryKeyIdMap);

      return most.from([
        initialize(
          'inlinePrivacy',
          {
            submit: 'accept',
            analytics: r.path([ 'analytics', 'value' ], options),
            support: r.path([ 'support', 'value' ], options),
            marketing: r.path([ 'marketing', 'value' ], options),
          },
        ),
      ]);
    }),
  )(actions);
}
